






import { defineComponent } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';

export default defineComponent({
  name: 'CopyRight',
  setup() {
    const { config } = useConfig();
    return {
      storeConfig: config,
    };
  },
});
