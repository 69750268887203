











import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
import type { CmsBlock } from '~/modules/GraphQL/types';
import CustomBlock from '~/components/CustomBlock.vue';
import Preload from "~/components/Preload.vue";

export default defineComponent({
  name: 'CustomBlocks',
  components: {
    CustomBlock,
    Preload,
  },
  props: {
    identifiers: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props) {
    const {
      loadBlocks,
    } = useContent();
    const blocks = ref<CmsBlock[]>([]);
    const isLoaded = ref(false);

    useFetch(async () => {
      if (props.identifiers) {
        blocks.value = await loadBlocks({ identifiers: props.identifiers });
        isLoaded.value = true;
      }
    });

    return {
      blocks,
      isLoaded,
    };
  },
});
