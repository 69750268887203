














import { computed, defineComponent } from "@nuxtjs/composition-api";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
export default defineComponent({
	name: "Preload",
	props: {
		height: {
			type: Number,
			default: 0,
		},
	},
	components: {
		SkeletonLoader,
	},
	setup(props) {
		const heights = props.height;
		const items = computed(() => Math.floor(heights / 36) || 1);

		return {
			heights,
			items,
		};
	},
});
